<template lang="pug">
  v-dialog(width="320" v-model="showModal")
    template(v-slot:activator="{ on }")
      v-icon.change-color(v-on="on" data-test="change-color-btn" :disabled="disabled") mdi-format-color-fill
    v-card(data-test="change-color-modal")
      v-card-title
        v-icon.back-icon(v-if="editableColor" @click="cancel") mdi-arrow-left
        p.change-color-title Edit color
        v-icon.close-icon(@click="showModal = false") mdi-close
      v-card-text
        div.add-color(v-if="editableColor" data-test="add-edit-color")
          v-text-field(
            type="number"
            placeholder="Order"
            v-model="editableColor.order"
            :error="formErrors.hasError('order')"
            :error-messages="formErrors.fields.order"
            data-test="order-field"
          )
          v-color-picker(mode="hexa" hide-mode-switch v-model="editableColor.hex_color_code")
          div.buttons
            v-btn.save-button(@click="save" color="primary" data-test="color-save-btn") Save
            v-btn.cancel-button(@click="cancel" data-test="color-cancel-btn") Cancel
        div.color-list
          div.color-list__item(v-for="item in colors" :key="item.ID" data-test="color-item")
            p {{ item.order }}
            div.color-value(:style="{'background-color': item.hex_color_code}" @click="onChooseColor(item)" data-test="color-choose-btn")
            v-icon(@click="onEdit(item)" x-small data-test="color-edit-btn") mdi-pencil
            v-icon.margin-l-8(@click="deleteColor(item.id)" v-if="ise2e" x-small data-test="color-delete-btn") mdi-delete
        v-btn.add-button(v-if="!editableColor" color="primary" @click="onAdd" data-test="add-new-color-btn") Add New
        v-btn.clear-button(v-if="!editableColor" @click="clearColor") Clear Color
</template>

<script>
import { CandidateColor } from '../core/models/candidateColorModel'
import ErrorMixin from "@/mixins/errors.mixin"
import FormErrors from "@/util/form-errors"
import { mapState, mapActions } from 'vuex'

let ise2e = process.env.NODE_ENV === 'e2e'

export default {
  name: 'CandidatesColor',

  mixins: [ErrorMixin],

  inject: ['svc'],

  props: {
    activeItems: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    page: { type: String, default: 'CANDIDATES' }
  },

  data: () => ({
    colorBeforeEdit: null,
    formErrors: new FormErrors(),
    showModal: false,
    editableColor: null,
    ise2e: ise2e
  }),

  computed: {
    ...mapState('crmCandidateColors', {
      _colors: state => state.colors
    }),

    colors() {
      return this._colors.filter(color => color.page.includes(this.page))
    },

    nextOrder() {
      return this.colors[this.colors.length - 1].order + 1
    },

    isEdit() {
      return this.editableColor && this.editableColor.id
    }
  },

  watch: {
    showModal(value) {
      if (!value) this.cancel()
    }
  },

  methods: {
    ...mapActions({
      createColor: 'crmCandidateColors/createColor',
      updateColor: 'crmCandidateColors/updateColor',
      deleteColor: 'crmCandidateColors/deleteColor',
    }),

    validateOrderRequiredField() {
      this.formErrors.validField('order')
      if (!this.editableColor.order.toString().length) {
        this.formErrors.invalidField('order', 'Order is required field')
        return
      }
      this.validateOrderDuplicated();
    },

    validateOrderDuplicated() {
      const ordersList = this.isEdit ?
          this.colors.map(color => +color.order).filter(id => id !== +this.colorBeforeEdit.order) :
          this.colors.map(color => +color.order)

      if (ordersList.includes(+this.editableColor.order)) {
        this.formErrors.invalidField('order', 'Duplicated order')
      }
    },

    async onChooseColor(color) {
      const candidatesIds = this.activeItems.map(item => item.ID)
      await this.svc().saveColor(candidatesIds, color.id)
      this.showModal = false;
    },

    async clearColor() {
      const candidatesIds = this.activeItems.map(item => item.ID)
      await this.svc().saveColor(candidatesIds, null)
      this.showModal = false;
    },

    onAdd() {
      this.editableColor = new CandidateColor({ page: this.page, order: this.nextOrder.toString() })
    },

    onEdit(item) {
      this.editableColor = new CandidateColor({ page: this.page, ...item })
      this.colorBeforeEdit = new CandidateColor(item)
    },

    async save() {
      this.validateOrderRequiredField();

      if (!this.formErrors.isValid()) {
        return this.$notify({ type: 'error', text: 'invalid field' })
      }
      if (this.formErrors.isValid()) {
        if (this.isEdit) {
          try {
            await this.updateColor(this.editableColor)
            this.cancel()
          } catch (err) {
            this.processErrorWithForm(err, {
              redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
              formErrors: this.formErrors,
              apiAttributes: {
                order: 'order',
                hex_color_code: 'hex_color_code'
              },
            });
          }
        } else {
          try {
            await this.createColor(this.editableColor)
            this.cancel()
          } catch (err) {
            this.processErrorWithForm(err, {
              redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
              formErrors: this.formErrors,
              apiAttributes: {
                order: 'order',
                hex_color_code: 'hex_color_code'
              },
            });
          }
        }
      }
    },

    cancel() {
      this.editableColor = null;
    }
  }
}
</script>

<style lang="scss" scoped>

.change-color {
  cursor: pointer;

  &-title {
    width: 100%;
    text-align: center;
  }
}

.back-icon {
  position: absolute;
  top: 18px;
  left: 16px;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 18px;
}

.add-color {
  margin-bottom: 16px;
}

.color-list {

  &__item {
    display: flex;
    align-items: center;
    margin-top: 8px;

    p {
      margin: 0;
    }

    .color-value {
      height: 20px;
      width: 200px;
      border-radius: 8px;
      margin: 0 16px;
      cursor: pointer;
    }
  }
}

.buttons {
  text-align: right;
}

.add-button {
  text-align: center;
  margin: 8px 0;
}

.clear-button {
  margin: 8px 4px;
}

.save-button, .cancel-button {
  text-align: center;
  margin-left: 4px;
  margin-top: 8px;
}

.margin-l-8 {
  margin-left: 8px;
}
</style>
